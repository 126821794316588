import { contactSubmit } from './contact.js';
import { AutomatitCarousel } from './automatit_carousel.m';

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message']
	});
}

/* eslint-disable*/

/////////////////////
// >Document Ready //
/////////////////////
$(function(){


	////////////////////
	// >>Double Click //
	////////////////////
	/**
	 * Require double clicks for anchor tags with class='d_click'
	 */
	 $('.d_click').click(function(e){
	 	if($('body').hasClass('touch')){
	 		if($(this).data('clicks') != 1)
	 			{e.preventDefault();}
	 		$(this).attr('data-clicks', 1);
	 	}
	 });

	////////////////////////////
	// >>FAQs Event Listeners //
	////////////////////////////
	$(".question_box").on("click", function() {
		if(!$(this).find(".answer_box").hasClass("active")) {
			$(this).find(".answer_box").slideToggle();
			$(this).find(".down_arrow").addClass("active");
			$(this).find(".answer_box").addClass("active");
		} else if($(this).find(".answer_box").hasClass("active")) {
			$(this).find(".answer_box").slideToggle();
			$(this).find(".down_arrow").removeClass("active");
			$(this).find(".answer_box").removeClass("active");
		}
	});

	///////////////////
	// >>Mobile Menu //
	//////////////////
	$("#hamburger").click(function(){
		$(this).addClass("spin");
		$(this).toggleClass("active");
		$("#mobile_nav").toggleClass("active");
		$("#container").toggleClass("fixed");

		setTimeout(function(){
			$("#hamburger").removeClass("spin");
		}, 750);
	});



	if($(".facilities_springboard").length > 0) {
		// $(".fac_card").click(function(){
		// 	window.location.href = $(this).data("link");
		// });
		if($('#the_actual_map').length > 0)
		{multiMap();}
	}

	$("#nearby_locations .btn_outline").click(function(){
		window.location.href = $(this).data("link");
	});

	//HOME
	if(document.querySelector('#index')) {
		var hero_imgs = [
			{src: "/static/images/new_images/1_13.jpg", alt: "View of the newly constructes facility"},
			{src: "/static/images/new_images/1_89.jpg", alt: "View of the gated facility antrance"}
		];
		AutomatitCarousel({
			speed: 1200,
			intervalTiming: 5500,
			showDots: true,
			useChevrons: false,
			imagesAsBackgrounds: true,
			images: hero_imgs,
			element: document.querySelector('#hero_caro')
		});
		// AutomatitCarousel({
		// 	speed: 500,
		// 	easing: "swing",
		// 	useChevrons: true,
		// 	intervalTiming: false,
		// 	showDots: false,
		// 	autoVerticalCentering: true,
		// 	element: document.querySelector('#testimonial_carousel')
		// });
	}


	if($('.unit_row').length > 0){
		$('.unit_row .unit_size_img').each(function(){
			var size = $(this).data('size');
			console.log(size);
			if(size < 50){
				$(this).addClass('one');
				$(this).attr('href', '/what-size-unit/what-fits#tiny');

			} else if(size >= 50 && size < 75){
				$(this).addClass('two');
				$(this).attr('href', '/what-size-unit/what-fits#small');
			} else if(size >= 75 && size < 100){
				$(this).addClass('three');
				$(this).attr('href', '/what-size-unit/what-fits#small');
			} else if(size >= 100 && size < 150){
				$(this).addClass('four');
				$(this).attr('href', '/what-size-unit/what-fits#medium');
			} else if(size >= 150 && size < 200){
				$(this).addClass('five');
				$(this).attr('href', '/what-size-unit/what-fits#large');
			} else if(size >= 200 && size < 300){
				$(this).addClass('six');
				$(this).attr('href', '/what-size-unit/what-fits#huge');
			}
			else{
				$(this).addClass('six');
				$(this).attr('href', '/what-size-unit/what-fits#gigantic');
			}
		});
	}
	$('.unit_row').click(function(){
		if($(window).width() <= 750){
			if($(this).hasClass('rent_only')){
				$(this).find('.mobile_reserve .reserve_btn').hide();
			}
			$(this).find('.mobile_reserve').toggleClass('active');
		}
	});

	////////////
	// >>Maps //
	////////////
	if($('#single_map').length > 0)
	{singleMap();}
	if($('#the_actual_map').length > 0)
	{multiMap();}

	  ////////////////////
	 //// >Rates Page //
	////////////////////

	if(document.querySelector('#testimonial_carousel')) {		
		AutomatitCarousel({
			speed: 500,
			easing: "swing",
			useChevrons: true,
			intervalTiming: false,
			showDots: false,
			autoVerticalCentering: true,
			element: document.querySelector('#testimonial_carousel')
		});
	}

	  ////////////////////////
	 //// >>Start Carousle //
	////////////////////////
	if($('#view_carousel').length > 0){
		AutomatitCarousel({
			useChevrons: false,
			showDots: true,
			element: document.querySelector('#view_carousel')
		});
	}

	////////////////////
	//// >>OVERLAY ////
	//////////////////
	// Call click function
	$(".open_overlay").on("click", function() {
		$("body").addClass("disable_scroll");
		if($(this).attr("data-action") === "call") {
			$("#call_pay_overlay").addClass("active");
			$("#call_pay_overlay").attr("data-otype", "call");
			$(".call_btn").css("display", "block");
		} else if ($(this).attr("data-action") === "pay") {
			$("#call_pay_overlay").addClass("active");
			$("#call_pay_overlay").attr("data-otype", "pay");
			$(".pay_btn").css("display", "block");
		}
	});

	// Exit function
	$(".overlay_box .exit_btn, #call_pay_overlay").on("click", function() {
		$("#call_pay_overlay").removeClass("active");
		$("body").removeClass("disable_scroll");
		$(".call_btn,.pay_btn").css("display", "none");
	});
	$(".overlay_box").on("click", function(e) {
		e.stopImmediatePropagation();
	});

	// Call Link

	$(".inner_overlay_box .loc_row").click(function(){
		if($("#call_pay_overlay").attr("data-otype") === "call") {
			window.location.href = $(this).data("phonelink");
		}
	});


	// Pay Link
	// $(".inner_overlay_box .loc_row").click(function(){
	// 	if($("#call_pay_overlay").attr("data-otype") === "pay") {
	// 		window.location.href = $(this).data("paylink");
	// 	}
	// });



	////////////////////
	//// >>CONTACT ////
	//////////////////
	// if($('#contact.internal').length > 0){
	// 	var subIP = false;

	// 	$('#contact_form').on('submit', function(e){

	// 		if($('#hp').val() != '')return false;

	// 		$('#contact_form .error').removeClass('error');
	// 		$('.response_message').html('');

	// 		if(subIP) {
	// 			return;
	// 		}

	// 		var checkFields = new Array('name', 'email', 'facility','message');
	// 		var errorFound = false;
	// 		checkFields.forEach(function(item){
	// 			if($('#'+item+'_input').val() == ''){
	// 				$('#'+item+'_input').addClass('error');
	// 				errorFound = true;
	// 			}
	// 		});
	// 		var formdata = $('#contact_form').serialize();
	// 		var formAction = $('#contact_form').attr('action');
	// 		if(! subIP && ! errorFound){
	// 			subIP = true;
	// 			$.ajax({
	// 				data: formdata,
	// 				method: 'POST',
	// 				url: formAction,
	// 				success: function(returnData,status){
	// 					$('.response_message').html("<span>Message sent! We will contact you soon.</span>");
	// 					if($('.response_message .error').length == 0){
	// 						$('#contact_form_submit').detach();
	// 					}
	// 				},
	// 				error: function(){
	// 					$('.response_message').html("<span class='error'>There was a problem processing your request, please try again!</span>");
	// 				},
	// 				complete: function(){
	// 					subIP = false;
	// 				}
	// 			});
	// 		}
	// 		else {
	// 			$('.response_message').html("<span class='error'>Please fill out all required fields.</span>");
	// 		}
	// 		e.stopImmediatePropagation();
	// 		e.preventDefault();
	// 	});
	// }
	if($("#size_guide").length > 0){
		AutomatitCarousel({
			speed: 500,
			easing: "swing",
			useChevrons: true,
			intervalTiming: false,
			showDots: false,
			autoVerticalCentering: true,
			element: document.querySelector('#size_guide')
		});
	}
});


//>size guide slide

////////////////
// >Functions //
////////////////
/**
 * >>singleMap()
 * Turns #single_map into a map with a single map marker.
 * Requires #single_map with data-lat and data-lng.
 */
function singleMap(){
 	var lat = $('#single_map').data('lat');
 	var lng = $('#single_map').data('lng');
 	var pin = new google.maps.LatLng(lat, lng);
 	var myOptions = {
 		mapTypeId: google.maps.MapTypeId.ROADMAP,
 		scrollwheel: false
 	};
 	var map = new google.maps.Map(document.getElementById("single_map"), myOptions);
 	var marker = new google.maps.Marker({
 		position: pin,
 		icon: '/static/images/icons/utilities/mappin.png',
 		animation: google.maps.Animation.DROP,
 		map: map
 	});
 	map.setCenter(pin);
 	map.setZoom(15);
}

/**
 * >>multiMap()
 * Turns #map_canvas into a map with a marker for every .data_source.
 * Requires #map_canvas and at least one .data_source with data-lat and data-lng.
 */
var myMapGlobal;
var myBoundsGlobal;

function multiMap(){
 	var map = new google.maps.Map(document.getElementById('the_actual_map'),{
 		scrollwheel: false,
 	});
 	var markers = [];
 	var locations = $('.data_source');
 	var searchLatLng = [];
 	var bounds = new google.maps.LatLngBounds();
 	locations.each(function(i) {
 		$(this).data('index', i);
 		var j = i;
 		var link = $(this).data('link');
 		var lat = parseFloat($(this).data('lat'));
 		var lng = parseFloat($(this).data('lng'));
 		var map_dir = $(this).data('maps');
 		searchLatLng.push(new google.maps.LatLng(lat,lng));
 		markers.push(new RichMarker({
 			position: searchLatLng[i],
 			map: map,
 			animation: google.maps.Animation.DROP,
 			content: '<a class="map_pin" target="_blank" data-index="'+i+'" href=https://www.google.com/maps/dir//'+map_dir+'><span class="pin_num">'+ ++j+'</span></a>',
 			shadow: false
 		}));
 		bounds.extend(searchLatLng[i]);
 	});

 	window.infoWindow = new google.maps.InfoWindow();

 	myBoundsGlobal = bounds;
 	if(markers.length > 1)
 		{map.fitBounds(bounds);}
 	// else{
 	// 	map.setCenter(markers[0].getPosition());
 	// 	map.setZoom(15);
 	// }

 	$('.data_source').hover(function() {
 		var index = $('.data_source').index(this);
 		$('#the_actual_map .map_pin').eq(index).toggleClass('hover');
 	});

 	google.maps.event.addListenerOnce(map, 'idle', function(){
 		$('#the_actual_map .map_pin').hover(function() {
 			var index = $(this).data('index');
 			$('.data_source').eq(index).toggleClass('hover');
 		});
 	});

 	myMapGlobal = map;
}

////////////////////////////////////
// >Disable hover on touchscreens //
////////////////////////////////////
/**
 * Adds class 'touch' to body if device is touchscreen.
 */
if('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
 	{document.body.className += ' ' + 'touch';}
